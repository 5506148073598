import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../types/login-credentials';
import { Observable } from 'rxjs';
import { IC_SSO_CHALLENGE } from '../auth.constants';
import { MsalAccessInfo } from '../types/msal-access-info';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  private $http = inject(HttpClient);
  private apiUsersUrl = `${environment.apiUrl}/users`;

  /**
   * Logs in user with provided credentials.
   *
   * @param {LoginCredentials} loginCredentials - The user's login credentials.
   * @returns {Observable<{ token: string }>} - Observable that emits the authentication token.
   */
  loginWithUserCredentials(
    loginCredentials: LoginCredentials
  ): Observable<{ token: string }> {
    return this.$http.post<{ token: string }>(
      `${this.apiUsersUrl}/authenticate`,
      loginCredentials
    );
  }

  /**
   * Retrieves authentication information from Microsoft by exchanging an authorization code.
   *
   * @param {string} code - The authorization code received from Microsoft.
   * @returns {Observable<MsalAccessInfo>} - An Observable that emits an instance of MsalAccessInfo, which contains the access token and other authentication information.
   */
  getAuthInformationFromMicrosoft(code: string): Observable<MsalAccessInfo> {
    const challenge = localStorage.getItem(IC_SSO_CHALLENGE);
    const codeVerifier = JSON.parse(challenge).code_verifier;
    const body =
      `client_id=${environment.msalConfig.clientId}&scope=${environment.msalConfig.scope}&code=${code}` +
      `&redirect_uri=${environment.msalConfig.redirectUri}&grant_type=authorization_code&code_verifier=${codeVerifier}`;
    const url = `${environment.msalConfig.url}/${environment.msalConfig.authority}/oauth2/v2.0/token`;
    return this.$http.post<MsalAccessInfo>(url, body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  /**
   * Retrieves a new refresh token from the server.
   *
   * @param {string} refreshToken - The refresh token to be used to obtain a new access token.
   * @return {Observable<MsalAccessInfo>} - An observable that emits the new access token and related information.
   */
  getNewRefreshToken(refreshToken: string): Observable<MsalAccessInfo> {
    const url = `${environment.msalConfig.url}/${environment.msalConfig.authority}/oauth2/v2.0/token`;
    const body = `client_id=${environment.msalConfig.clientId}&scope=${environment.msalConfig.scope}&refresh_token=${refreshToken}&grant_type=refresh_token`;
    return this.$http.post<MsalAccessInfo>(url, body, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
    });
  }

  /**
   * Retrieves the JWT token by the provided OAuth token.
   *
   * @param {string} oAuthToken - The OAuth token used for authentication.
   * @returns {Observable<{ token: string }>} - An observable which emits an object containing the JWT token.
   */
  getJwtTokenByOAuthToken(oAuthToken: string): Observable<{ token: string }> {
    return this.$http.get<{ token: string }>(`${this.apiUsersUrl}/token`, {
      headers: { 'OAuth-Token': oAuthToken },
    });
  }
}
